// CartContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as cartUtils from '../helpers/cartUtils';

const CartContext = createContext();

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    console.error("useCart must be used within a CartProvider");
    debugger
  }
  return context;
}

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setCart(cartUtils.getCart());
  }, []);

  const addToCart = (item) => {
    cartUtils.addToCart(item);
    setCart(cartUtils.getCart());
  };

  const removeFromCart = (id) => {
    cartUtils.removeFromCart(id);
    setCart(cartUtils.getCart());
  };

  const updateCartItemQuantity = (id, quantity) => {
    cartUtils.updateCartItemQuantity(id, quantity);
    setCart(cartUtils.getCart());
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateCartItemQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
