// Helper function to get the cart from LocalStorage
function getCart() {
  const cartJSON = localStorage.getItem('cart');
  return cartJSON ? JSON.parse(cartJSON) : [];
}

// Function to save the cart to LocalStorage
function saveCart(cart) {
  localStorage.setItem('cart', JSON.stringify(cart));
}

// Function to add an item to the cart
function addToCart(item) {

  const cart = getCart();
  const existingItem = cart.find(cartItem => cartItem.id === item.id);

  if (existingItem) {
    existingItem.quantity += item.quantity; // Update quantity if item already exists
  } else {

    cart.push(item); // Add new item to the cart
  }

  saveCart(cart);
}

// Function to update the quantity of an item in the cart
function updateCartItemQuantity(id, quantity) {
  const cart = getCart();
  const item = cart.find(cartItem => cartItem.id === id);

  if (item) {
    item.quantity = quantity;
    saveCart(cart);
  }
}

// Function to remove an item from the cart
function removeFromCart(id) {
  let cart = getCart();
  cart = cart.filter(cartItem => cartItem.id !== id);
  saveCart(cart);
}


export {
  getCart,
  saveCart,
  addToCart,
  updateCartItemQuantity,
  removeFromCart
};